import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "student-letter" }
const _hoisted_2 = { class: "student-letter__latter-wrap" }
const _hoisted_3 = { class: "student-letter__wrap-flex" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "student-letter__wrap-flex" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "student-letter__letter-qr-wrap" }
const _hoisted_8 = { class: "student-letter__letter-qr-name" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "student-letter__copied"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Google = _resolveComponent("Google")!
  const _component_Copy = _resolveComponent("Copy")!
  const _component_Apple = _resolveComponent("Apple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "student-letter__letter-top-text" }, " Your account has been created by the admin. Follow the steps below to log in: ", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "student-letter__letter-bold-text" }, "👉 For Android Users:", -1)),
      _cache[6] || (_cache[6] = _createElementVNode("div", null, "Click below to download the app and log into your account.", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: _ctx.deepLink,
          class: "student-letter__download-wrap"
        }, [
          _createVNode(_component_Google),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "student-letter__download-container" }, [
            _createTextVNode(" Get it on "),
            _createElementVNode("div", null, "Google Play")
          ], -1))
        ], 8, _hoisted_4),
        _createVNode(_component_Copy, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (this.copyText(_ctx.deepLink)))
        })
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "student-letter__letter-bold-text" }, "👉 For iOS Users:", -1)),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "student-letter__letter-bold-step" }, " Step 1: Install the App: ", -1)),
      _cache[9] || (_cache[9] = _createElementVNode("div", null, "Click below to download the app.", -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", {
          href: _ctx.appLink,
          class: "student-letter__download-wrap"
        }, [
          _createVNode(_component_Apple),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "student-letter__download-container" }, [
            _createTextVNode(" Get it on "),
            _createElementVNode("div", null, "App Store")
          ], -1))
        ], 8, _hoisted_6),
        _createVNode(_component_Copy, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (this.copyText(_ctx.appLink)))
        })
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "student-letter__letter-bold-step" }, " Step 2: Log In Using Your QR Code ", -1)),
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "student-letter__letter-text" }, " 📌 After installing the app, scan this QR code again to log into your account: ", -1)),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.fullName), 1),
        _createElementVNode("img", {
          src: _ctx.qrCodeUrl,
          alt: "qr"
        }, null, 8, _hoisted_9)
      ]),
      _cache[12] || (_cache[12] = _createStaticVNode("<div class=\"student-letter__letter-bold-step\" data-v-06b26fa6> Step 3: Alternative Login Option </div><div class=\"student-letter__letter-text\" data-v-06b26fa6> 🔹 If you can’t scan the QR code, you can still log in manually: </div><div class=\"student-letter__letter-text\" data-v-06b26fa6>• Open the app.</div><div class=\"student-letter__letter-text\" data-v-06b26fa6> • Enter your email during login. </div><div class=\"student-letter__letter-text\" data-v-06b26fa6> • Click ‘Reset Password’ to create a new password. </div>", 5))
    ]),
    (_ctx.copied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Code copied!"))
      : _createCommentVNode("", true)
  ]))
}