<template>
  <div class="calendar">
    <div class="header">
      <button @click="prevMonth" class="prev">
        <Arrow />
      </button>
      <h2>{{ this.monthName }} {{ this.year }}</h2>
      <button @click="nextMonth">
        <Arrow />
      </button>
    </div>
    <div class="days-of-week">
      <div v-for="day in daysOfWeek" :key="day">{{ day }}</div>
    </div>
    <div class="dates">
      <div v-for="date in dates" :key="date.key">
        <div
          v-if="date.value > 0"
          class="days"
          :class="{
            monday: isMonday(date, this.month, this.year),
            sunday: isSunday(date, this.month, this.year),
            'first-day': isFirstDayOfMonth(date, this.month, this.year),
            'last-day': isLastDayOfMonth(date, this.month, this.year),
          }"
          @click="selectDateFill(date, this.month, this.year)"
        >
          <div
            class="daysWrap"
            @click="selectDateFillNew(date, this.month, this.year)"
            :class="{ selected: isSelectedDate(date, this.month, this.year) }"
          >
            {{ date.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Arrow from "@/assets/svg/arrow-calendar.svg?inline";
export default {
  data() {
    return {
      daysOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      month: "",
      monthName: "",
      year: "",
      dates: [],
      today: "",
      selectedDate: null,
      currentMonth: null,
      selectedMonth: null,
      currentYear: null,
      selectedYear: null,
      selectedStartDate: null,
      selectedEndDate: null,
    };
  },
  props: {
    selectDateFill: {
      type: Function,
      default() {
        return {};
      },
    },
    selectedNewDate: String,
  },
  components: {
    Arrow,
  },
  methods: {
    prevMonth() {
      if (this.month === 0) {
        this.month = 11;
        this.year--;
      } else {
        this.month--;
      }
      this.dates = [];
      this.updateDates();
    },
    nextMonth() {
      if (this.month === 11) {
        this.month = 0;
        this.year++;
      } else {
        this.month++;
      }
      this.dates = [];
      this.updateDates();
    },
    updateDates() {
      const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
      let firstDayOfMonth = new Date(this.year, this.month, 1).getDay();
      while (firstDayOfMonth !== 1) {
        this.dates.push({ value: "", classes: "prev-month" });
        firstDayOfMonth = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
      }
      const dates = [];
      for (let i = 1; i <= daysInMonth; i++) {
        dates.push({ value: i, classes: "" });
      }
      this.dates = [...this.dates, ...dates];
      this.today = new Date().getDate();
      const MONTH_NAMES = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      this.monthName = MONTH_NAMES[this.month];
    },
    selectDateFillNew(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      this.selectedStartDate = selectedDate;
    },
    isSelectedDate(date, month, year) {
      const selectedDate = new Date(this.selectedStartDate);
      return (
        selectedDate.getDate() === date.value &&
        selectedDate.getMonth() === month &&
        selectedDate.getFullYear() === year
      );
    },
    isMonday(date, month, year) {
      const dayOfWeek = new Date(year, month, date.value).getDay();
      return dayOfWeek === 1;
    },
    isSunday(date, month, year) {
      const dayOfWeek = new Date(year, month, date.value).getDay();
      return dayOfWeek === 0;
    },
    isFirstDayOfMonth(date) {
      return date.value === 1;
    },
    isLastDayOfMonth(date, month, year) {
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      return date.value === daysInMonth;
    },
  },
  mounted() {
    const now = new Date();
    this.month = now.getMonth();
    this.currentMonth = now.getMonth();
    this.year = now.getFullYear();
    this.currentYear = now.getFullYear();
    this.updateDates();
  },
};
</script>
<style lang="scss" scoped>
.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;

  h2 {
    font-weight: 400;
    font-size: 16px;
  }

  button {
    background: transparent;
    border: none;
    border-radius: 8px;
    background: #eff5fb;
    width: 60px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
  .prev svg {
    transform: rotate(180deg);
  }
}

.days-of-week .first-day {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.days-of-week .last-day {
  // border-top-right-radius: 5px;
  // border-bottom-right-radius: 5px;
}
.daysWrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.days-of-week {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
  div {
    width: calc(100% / 7);
  }
}

.dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}

.dates .days {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 56px;
  cursor: pointer;
  margin-top: 5px;
  background: #eff5fb;

  // div &:hover {
  //   background: #47573e !important;
  //   border-radius: 10px;
  //   color: #ffffff;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 40px;
  //   width: 54px;
  // }
}

.monday {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.sunday {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.first-day {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.last-day {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.today {
  background: #06c !important;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 54px;
}

.selected {
  border-radius: 8px;
  background: #06c;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 54px;
}

.prev-month,
.next-month {
  opacity: 0.5;
  cursor: pointer;
  color: #bbb;
}

.prev-month:hover,
.next-month:hover {
  opacity: 1;
}

.prev-month {
  visibility: hidden;
}

.calendar:hover .prev-month {
  visibility: visible;
}

.next-month {
  visibility: hidden;
  //   display: none!important;
}

.calendar:hover .next-month {
  visibility: visible;
}
</style>
