
import { defineComponent } from "vue";

//svg
import Apple from "@/assets/svg/apple.svg?inline";
import Google from "@/assets/svg/google.svg?inline";
import Copy from "@/assets/svg/copy.svg?inline";

export default defineComponent({
  name: "StudentLetter",
  data() {
    return {
      copied: false
    };
  },
  props: {
    fullName: String,
    qrCodeUrl: null,
    appLink: null,
    deepLink: null,
  },
  components: {
    Apple,
    Google,
    Copy,
  },
  mounted() {},
  methods: {
    async copyText(link) {
      try {
        await navigator.clipboard.writeText(link);
        this.copied = true;
        setTimeout(() => (this.copied = false), 3000);
      } catch (error) {
        console.error("Failed to copy text: ", error);
      }
    }
  },
});
